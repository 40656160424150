import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Spin from '../views/Spin.vue'
import Login from '../views/Login.vue'
import Promotion from '../views/Promotion.vue'
import Games from '../views/Games.vue'
import PlayGame from '../views/PlayGame.vue'
import Gift from '../views/Gift.vue'
import Chinese from '../views/Chinese.vue'
import Boxs from '../views/Boxs.vue'
import Scratch from '../views/Scratch.vue'
import Register from '../views/Register.vue'
import History from '../views/History.vue'
import Redeem from '../views/Redeem.vue'
import HackSlot from '../views/HackSlot.vue'
import HackSlotSuccess from '../views/HackSlotSuccess.vue'
import Huay from '../views/Huay.vue'
import Roulet from '../views/Roulet.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Games
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: Promotion
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/games',
    name: 'games',
    component: Games
  },
  {
    path: '/playgame',
    name: 'playgame',
    component: PlayGame
  },
  {
    path: '/gift',
    name: 'gift',
    component: Gift
  },
  {
    path: '/chinese',
    name: 'chinese',
    component: Chinese
  },
  {
    path: '/boxs',
    name: 'boxs',
    component: Boxs
  },
  {
    path: '/scratch',
    name: 'scratch',
    component: Scratch
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/spin',
    name: 'spin',
    component: Spin
  },
  {
    path: '/lotto',
    name: 'lotto',
    component: Huay
  },
  {
    path: '/history',
    name: 'history',
    component: History
  },
  {
    path: '/redeem',
    name: 'redeem',
    component: Redeem
  },
  {
    path: '/hackslot',
    name: 'hackslot',
    component: HackSlot
  },
  {
    path: '/hackslotsuccess',
    name: 'hackslotsuccess',
    component: HackSlotSuccess
  },
  {
    path: '/roulet',
    name: 'roulet',
    component: Roulet
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!firebase.auth().currentUser) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
